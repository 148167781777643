/* source-sans-pro-regular - latin */

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/source-sans-pro-v12-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/source-sans-pro-v12-latin-regular.woff') format('woff'),
    url('./fonts/source-sans-pro-v12-latin-regular.ttf') format('truetype'),
    url('./fonts/source-sans-pro-v12-latin-regular.svg#sourceSansPro')
      format('svg');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: bold;
  font-weight: 700;
  src: url('./fonts/source-sans-pro-v12-latin-700.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/source-sans-pro-v12-latin-700.woff') format('woff'),
    url('./fonts/source-sans-pro-v12-latin-700.ttf') format('truetype'),
    url('./fonts/source-sans-pro-v12-latin-700.svg#sourceSansProBold')
      format('svg');
}

.spinner-center {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: transparent;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
